<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Jewel Layout',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

if (!isVoixTemplatizer)
  setupHeader('cab569fc-6f99-4eb0-872e-31d7bd61383c')

const {
  resorts,
  brands,
  currentResort,
  footerCompanyMenu,
  footerResourcesMenu,
  footerTermsMenu,
} = await getCommonLayoutData(isVoixTemplatizer)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#0a1c36' })
provide('currentResort', currentResort.value.data)
provide('resorts', resorts.value.data)
provide('brands', brands.value.data)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white jewelresorts jewel-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />

        <div id="playa-content">
          <NavigationHeader
            v-if="!isVoixTemplatizer"
            :config="{
              bookNowBackground: 'jewelresorts btn',
              stacked: false,
              menuPosition: 'right', // right or center
              backgroundClass: 'bg-white',
              paper: true,
            }"
          />
          <slot />
          <PlayaresortsFooter
            v-if="!isVoixTemplatizer"
            :current-resort="currentResort?.data"
            :footer-company-menu="footerCompanyMenu?.data"
            :footer-resources-menu="footerResourcesMenu?.data"
            :footer-terms-menu="footerTermsMenu?.data"
            bg-type="white"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/jewel.scss';
</style>
